
@media screen and (min-width: 1000px) {
    .promo-block {
        min-height: 310px;
    }

    .promo-block--small {
        min-height: 280px;
    }

    .promo-block--large {
        min-height: 360px;
    }

    .promo-block__image-wrapper {
        background-size: auto 260px;
    }

    .promo-block--small .promo-block__image-wrapper {
        background-size: auto 220px;
    }

    .promo-block__heading {
        margin-bottom: 8px;
    }

    .promo-block__cta {
        margin-top: 8px;
    }
}

.rte {
    word-break: break-word;
}

.rte:after {
    content: "";
    clear: both;
    display: block;
}

.rte h1, .rte h2, .rte h3, .rte h4, .rte h5, .rte h6 {
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    font-style: var(--heading-font-style);
    color: var(--heading-color);
    margin: 1em 0;
}

.rte h1:first-child, .rte h2:first-child, .rte h3:first-child, .rte h4:first-child, .rte h5:first-child, .rte h6:first-child {
    margin-top: 0;
}

.rte h1 {
    margin: 1.4em 0 .7em;
}

.rte h2 {
    margin: 1.55em 0 .6em;
}

.rte h3 {
    margin: 1.8em 0 .8em;
}

.rte h4 {
    margin: 1.9em 0 .6em;
}

.rte h5 {
    margin: 2.2em 0 .7em;
}

.rte h6 {
    margin: 2.5em 0 .6em;
}

.rte p:not(:last-child), .rte ul:not(:last-child), .rte ol:not(:last-child) {
    margin-bottom: .7em;
}

.rte a:not(.button) {
    color: var(--link-color);
    -webkit-text-decoration: var(--text-link-decoration);
    text-decoration: var(--text-link-decoration);
    text-underline-position: under;
    transition: color .2s ease-in-out;
}

.rte a:not(.button):hover {
    color: var(--link-color-hover);
}

.rte p + .button {
    margin-top: 1em;
}

.rte ul, .rte ol {
    margin-left: 18px;
}

.rte ul li, .rte ol li {
    position: relative;
}

.rte ul li:not(:last-child), .rte ol li:not(:last-child) {
    margin-bottom: 5px;
}

.rte ul {
    list-style: none;
}

.rte ul li:before {
    content: "";
    background: rgba(var(--text-color-rgb), .4);
    border-radius: 100%;
    width: 5px;
    height: 5px;
    position: absolute;
    top: .8em;
    left: -18px;
}

.rte ol {
    list-style-position: outside;
}

.rte img {
    margin: 1em 0;
}

.rte .video-wrapper {
    margin: 2.7em 0;
}

.rte blockquote {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 16px));
    border-left: 5px solid rgba(var(--text-color-rgb), .4);
    margin: 3em 0 3em 10px;
    padding-left: 20px;
}

.rte table {
    margin-top: 2em;
}

@media screen and (min-width: 641px) {
    .rte blockquote {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 18px));
        padding-left: 30px;
    }

    .rte .video-wrapper {
        margin: 3em 0;
    }
}

.section {
    margin: 50px 0;
}

.section--tight {
    margin: 30px 0;
}

.section--isolated {
    padding: 50px 0;
    margin: 0 !important;
}

.section--isolated.section--tight {
    padding: 40px 0;
}

.section--text-centered {
    text-align: center;
}

.section__header {
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 15px;
    display: flex;
    overflow: hidden;
}

.section__title {
    margin-bottom: 0;
}

.section__action-link {
    font-weight: var(--text-font-bolder-weight);
    color: var(--accent-color);
    flex-shrink: 0;
    margin-left: 20px;
}

.section__action-link svg {
    display: none;
}

.section__header-stack {
    flex-direction: column;
    display: flex;
}

.section--isolated .heading {
    color: inherit;
}

@media screen and (max-width: 640px) {
    .section__header-stack > .section__title + * {
        margin-top: 10px;
    }
}

@media screen and (min-width: 641px) {
    .section {
        margin: 60px 0;
    }

    .section--tight {
        margin: 40px 0;
    }

    .section--isolated {
        padding: 60px 0;
    }

    .section__header {
        margin-bottom: 20px;
        overflow: visible;
    }

    .section__header--tight {
        margin-bottom: 14px;
    }

    .section__header-stack {
        flex-direction: row;
        align-items: center;
    }

    .section__action-link {
        transition: transform .35s cubic-bezier(.645, .045, .355, 1);
        transform: translateX(26px);
    }

    .section__action-link svg {
        vertical-align: -2px;
        opacity: 0;
        width: 14px;
        height: 14px;
        margin-left: 8px;
        transition: all .35s cubic-bezier(.645, .045, .355, 1);
        display: inline-block;
        position: relative;
        right: -5px;
    }

    .section__header-stack > .section__title {
        margin: 0 16px 0 0;
    }
}

@media screen and (any-hover: hover) {
    .section__action-link:hover {
        transform: translateX(0);
    }

    .section__action-link:hover svg {
        opacity: 1;
        right: 0;
    }
}

.slideshow {
    height: 400px;
    overflow: hidden;
}

.slideshow--small {
    height: 350px;
}

.slideshow--large {
    height: 450px;
}

.slideshow__slide {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
}

.slideshow__slide-inner {
    text-align: center;
    background-position: center;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
}

.slideshow:not(.flickity-enabled) .slideshow__slide:not(.is-selected) {
    display: none;
}

.slideshow__placeholder {
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.slideshow__image {
    width: 100%;
}

.slideshow__image-placeholder {
    fill: #696969;
    background: #a9a9a9;
    height: 100%;
}

.slideshow__title {
    color: inherit;
    margin-bottom: 7px;
}

.slideshow__content-wrapper {
    z-index: 1;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 25px;
    position: relative;
}

.slideshow__content {
    max-width: 650px;
    margin: 0 auto 22px;
}

.slideshow .flickity-page-dots {
    bottom: 30px;
}

.slideshow .flickity-page-dots .dot {
    background: #fff;
    border: 3px solid #fff;
    width: 12px;
    height: 12px;
    margin: 0 8px;
    transition: background .25s ease-in-out;
    transform: none !important;
}

.slideshow .flickity-page-dots .dot:before {
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
}

.slideshow .flickity-page-dots .dot.is-selected {
    background: none;
}

.slideshow__slide--overlay:before {
    content: "";
    z-index: 1;
    background: linear-gradient(#0000, #0000004d);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.slideshow--preserve-ratio {
    height: auto !important;
}

.slideshow--preserve-ratio .slideshow__slide {
    height: auto;
}

.slideshow--preserve-ratio .slideshow__slide-inner {
    width: auto;
    height: auto;
    display: block;
    position: relative;
}

.slideshow--preserve-ratio .slideshow__content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (min-width: 641px) {
    .slideshow:not(.slideshow--edge2edge) {
        margin: var(--desktop-container-gutter) 0;
    }

    .slideshow:not(.slideshow--edge2edge) .flickity-viewport {
        border-radius: 3px;
    }

    .slideshow__image-placeholder {
        width: 100%;
        height: auto;
    }

    .slideshow__slide-inner--top-left, .slideshow__slide-inner--top-center, .slideshow__slide-inner--top-right {
        justify-content: flex-start;
    }

    .slideshow__slide-inner--bottom-left, .slideshow__slide-inner--bottom-center, .slideshow__slide-inner--bottom-right {
        justify-content: flex-end;
    }

    .slideshow__slide-inner--bottom-left .slideshow__content-wrapper, .slideshow__slide-inner--bottom-center .slideshow__content-wrapper, .slideshow__slide-inner--bottom-right .slideshow__content-wrapper {
        padding-top: 25px;
        padding-bottom: 75px;
    }

    .slideshow__slide-inner--top-left, .slideshow__slide-inner--middle-left, .slideshow__slide-inner--bottom-left {
        text-align: left;
    }

    .slideshow__slide-inner--top-left .slideshow__content, .slideshow__slide-inner--middle-left .slideshow__content, .slideshow__slide-inner--bottom-left .slideshow__content {
        margin-left: 0;
    }

    .slideshow__slide-inner--top-right, .slideshow__slide-inner--middle-right, .slideshow__slide-inner--bottom-right {
        text-align: right;
    }

    .slideshow__slide-inner--top-right .slideshow__content, .slideshow__slide-inner--middle-right .slideshow__content, .slideshow__slide-inner--bottom-right .slideshow__content {
        margin-right: 0;
    }

    .slideshow--preserve-ratio .slideshow__slide-inner--top-left .slideshow__content-wrapper, .slideshow--preserve-ratio .slideshow__slide-inner--top-center .slideshow__content-wrapper, .slideshow--preserve-ratio .slideshow__slide-inner--top-right .slideshow__content-wrapper {
        top: 0;
        transform: translateX(-50%);
    }

    .slideshow--preserve-ratio .slideshow__slide-inner--bottom-left .slideshow__content-wrapper, .slideshow--preserve-ratio .slideshow__slide-inner--bottom-center .slideshow__content-wrapper, .slideshow--preserve-ratio .slideshow__slide-inner--bottom-right .slideshow__content-wrapper {
        top: auto;
        bottom: 0;
        transform: translateX(-50%);
    }
}

@media screen and (min-width: 1000px) {
    .slideshow {
        height: 430px;
    }

    .slideshow--small {
        height: 380px;
    }

    .slideshow--large {
        height: 520px;
    }
}

.store-availability-container {
    margin-top: 26px;
}

.store-availability-information {
    align-items: baseline;
    display: flex;
}

.icon--store-availability-in-stock, .icon--store-availability-out-of-stock {
    width: 18px;
    height: 14px;
    position: relative;
}

.store-availability-information .icon--store-availability-in-stock {
    top: 2px;
}

.store-availability-information .icon--store-availability-out-of-stock {
    top: 3px;
}

.store-availability-information-container {
    margin-left: 10px;
}

.store-availability-information__title, .store-availability-list__location {
    margin-bottom: -2px;
}

.store-availability-information__stock {
    margin-bottom: 4px;
}

.store-availability-information__stock, .store-availability-list__item-info {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
}

.store-availability-list__item + .store-availability-list__item {
    border-top: 1px solid var(--border-color);
    margin-top: 22px;
    padding-top: 20px;
}

.store-availability-list__stock {
    margin-bottom: 2px;
}

.store-availability-list__stock svg {
    margin-right: 3px;
}

.store-availability-list__contact {
    line-height: 1.45;
}

.store-availability-list__stock .icon--store-availability-in-stock {
    top: -1px;
}

.store-availability-list__contact p {
    margin-bottom: 0;
}

.team {
    text-align: center;
    margin-top: 45px;
    margin-bottom: 62px;
}

.team__block-list {
    margin-bottom: 45px;
}

.team__block-item {
    margin-bottom: 20px;
}

@media screen and (min-width: 641px) {
    .team {
        margin-top: 62px;
        margin-bottom: 80px;
    }

    .team__block-list {
        flex-wrap: wrap;
        justify-content: center;
        margin: -15px -15px 47px;
        display: flex;
    }

    .team__block-item {
        flex: none;
        width: calc(50% - 30px);
        margin: 15px;
    }
}

@media screen and (min-width: 1000px) {
    .team__block-item {
        width: calc(33.3333% - 30px);
    }
}

@media screen and (min-width: 1280px) {
    .team__block-item {
        width: calc(25% - 30px);
    }
}

.team__section-title {
    margin-bottom: 25px;
}

@media screen and (min-width: 641px) {
    .team__section-title {
        margin-bottom: 40px;
    }
}

.team__member {
    border: 1px solid var(--border-color);
    background: var(--secondary-background);
    border-radius: 3px;
    padding: 30px 20px 20px;
}

.team__member-pic {
    border: 1px solid var(--border-color);
    background: var(--background);
    width: 144px;
    margin-bottom: 20px;
    padding: 8px;
}

.team__member-pic--rounded {
    border-radius: 50%;
}

.team__member-title, .team__member-subheading {
    margin-bottom: 0;
    line-height: 1.4;
}

.team__member-title {
    margin-bottom: 6px;
}

.team__member-link {
    margin-top: 6px;
    display: inline-block;
}

.team__promotion {
    border-radius: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    display: flex;
}

.team__promotion .heading {
    color: inherit;
    margin-bottom: 8px;
}

.team__promotion .button {
    min-width: 105px;
    margin-top: 22px;
}

.text-with-icons__item {
    width: 100%;
    line-height: 1.6;
}

.text-with-icons__title {
    margin-bottom: 4px;
}

.text-with-icons__icon-wrapper {
    margin-bottom: 15px;
}

.text-with-icons__icon-wrapper svg {
    vertical-align: middle;
    width: 30px;
    height: 30px;
}

.text-with-icons__icon-wrapper img {
    max-width: 30px;
}

.text-with-icons__content > p {
    margin-bottom: 0;
}

@media screen and (max-width: 640px) {
    .text-with-icons {
        text-align: center;
        padding-bottom: 28px;
    }

    .text-with-icons:after {
        content: "flickity";
        display: none;
    }

    .text-with-icons--stacked {
        padding-bottom: 0;
    }

    .text-with-icons--stacked .text-with-icons__item:not(:last-child) {
        margin-bottom: 34px;
    }

    .text-with-icons--stacked:after {
        content: "";
    }
}

@media screen and (min-width: 641px) {
    .text-with-icons {
        flex-wrap: wrap;
        margin: 0 -40px -45px;
        display: flex;
    }

    .page__header + .text-with-icons {
        margin-top: -5px;
    }

    .text-with-icons__item {
        flex-basis: calc(50% - 90px);
        margin: 0 40px 45px;
        display: flex;
    }

    .text-with-icons__icon-wrapper {
        margin: .5em 18px 0 0;
    }
}

@media screen and (min-width: 1000px) {
    .text-with-icons {
        justify-content: center;
        padding: 0 100px;
    }

    .text-with-icons__item {
        flex-basis: calc(50% - 140px);
        display: flex;
    }
}

@media screen and (min-width: 1280px) {
    .text-with-icons {
        flex-wrap: nowrap;
        justify-content: space-around;
        margin: 0 -35px;
        padding: 0;
    }

    .text-with-icons__item {
        flex-basis: calc(25% - 70px);
        margin: 0 35px;
    }
}

.text-with-icons--boxed {
    text-align: center;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    padding-bottom: 0;
    display: block;
}

.text-with-icons--boxed .text-with-icons__item {
    text-align: center;
    padding: 28px 35px;
    display: block;
}

.text-with-icons--boxed .text-with-icons__item:not(:first-child) {
    border-top: 1px solid var(--border-color);
}

.text-with-icons--boxed .text-with-icons__icon-wrapper {
    margin: 0 0 1em;
}

@media screen and (min-width: 641px) {
    .text-with-icons--boxed {
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;
        display: flex;
    }

    .text-with-icons--boxed .text-with-icons__item {
        flex: 1 0 0;
        margin: 0;
    }

    .text-with-icons--boxed .text-with-icons__item:not(:first-child) {
        border-top: none;
        border-left: 1px solid var(--border-color);
    }
}

.clearfix:before, .clearfix:after {
    content: "";
    display: table;
}

.text-center {
    text-align: center;
}

.skip-to-content:focus {
    clip: auto;
    color: var(--text-color);
    background-color: var(--secondary-background);
    opacity: 1;
    z-index: 10000;
    width: auto;
    height: auto;
    margin: 0;
    padding: 10px;
    transition: none;
}

.list--unstyled {
    list-style: none;
}

.collapsible {
    height: 0;
    transition: height .2s ease-in-out;
    overflow: hidden;
}

.social-media__item-list {
    flex-wrap: wrap;
    margin: -5px;
    display: flex;
}

.social-media__item {
    margin: 5px;
    display: inline-block;
}

.social-media__item > a {
    display: block;
}

.social-media__item svg {
    opacity: .4;
    will-change: opacity;
    width: 28px;
    height: 28px;
    transition: color .25s ease-in-out, opacity .25s ease-in-out;
}

.social-media__item-list--stack {
    margin: -10px 0;
    display: block;
}

.social-media__item-list--stack .social-media__item {
    margin: 0;
    padding: 7px 0;
    display: block;
}

.social-media__item-list--stack svg {
    vertical-align: top;
    margin-right: 12px;
}

@media screen and (any-hover: hover) {
    .social-media__item:hover svg {
        opacity: 1;
    }

    .social-media__item--facebook:hover svg {
        color: #3b5998;
    }

    .social-media__item--twitter:hover svg {
        color: #1da1f2;
    }

    .social-media__item--pinterest:hover svg {
        color: #bd081c;
    }

    .social-media__item--instagram:hover svg {
        color: #d83776;
    }

    .social-media__item--youtube:hover svg {
        color: red;
    }

    .social-media__item--tiktok:hover svg {
        color: #fd355a;
    }

    .social-media__item--vimeo:hover svg {
        color: #1ab7ea;
    }

    .social-media__item--linkedin:hover svg {
        color: #0077b5;
    }

    .social-media__item--snapchat:hover svg {
        color: #f5dc30;
    }

    .social-media__item--tumblr:hover svg {
        color: #35465c;
    }

    .social-media__item--fancy:hover svg {
        color: #494e58;
    }

    .social-media__item--rss:hover svg {
        color: #f26522;
    }
}

.alert {
    white-space: normal;
    word-break: break-all;
    word-break: break-word;
    background: var(--background);
    color: var(--heading-color);
    text-align: left;
    border-radius: 2px;
    margin-bottom: 20px;
    padding: 12px 20px;
    font-size: 1rem;
    display: block;
}

.alert:last-child {
    margin-bottom: 0;
}

.alert--block {
    display: block;
}

.alert--center {
    text-align: center;
}

.alert--tight {
    padding: 6px 14px;
}

.alert--error {
    background: var(--error-background);
    color: var(--error-color);
}

.alert--success {
    background: var(--success-background);
    color: var(--success-color);
}

.alert__ribbon {
    align-self: center;
    margin-left: 5px;
    margin-right: 25px;
}

.alert__ribbon svg {
    width: 24px;
    height: 24px;
    display: block;
}

.alert__error-list {
    list-style: none;
}

.alert a {
    text-decoration: underline;
    transition: color .2s ease-in-out;
}

.pagination {
    color: var(--heading-color);
    font-weight: var(--text-font-bolder-weight);
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
    border-top: 1px solid var(--border-color);
    padding: 40px 0;
}

.pagination__inner {
    position: relative;
}

.pagination__prev, .pagination__next {
    align-items: center;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
}

.pagination__prev svg, .pagination__next svg {
    width: 8px;
    height: 12px;
    position: relative;
}

.pagination__prev {
    left: 0;
}

.pagination__prev svg {
    margin-right: 14px;
}

.pagination__next {
    right: 0;
}

.pagination__next svg {
    margin-left: 14px;
}

.pagination__nav, .pagination__page-count {
    text-align: center;
    display: block;
}

.pagination__nav {
    display: none;
}

.pagination__nav-item {
    margin: 0 2px;
    padding: 8px 11px;
    line-height: 1;
    display: inline-block;
}

.pagination__nav-item.is-active {
    background: var(--primary-button-background);
    color: var(--primary-button-text-color);
    border-radius: 3px;
}

.card .pagination {
    padding: 20px;
}

@media screen and (min-width: 641px) {
    .pagination__nav {
        display: block;
    }

    .pagination__page-count {
        display: none;
    }

    .card .pagination {
        padding: 20px 30px;
    }
}

@media screen and (max-width: 999px) {
    .scroller {
        margin: 0 calc(-1 * var(--mobile-container-gutter));
        overflow: hidden;
    }

    .scroller--flush {
        margin: 0 !important;
    }

    .scroller__inner {
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        white-space: nowrap;
        margin-bottom: -20px;
        padding-bottom: 20px;
        overflow: auto hidden;
    }
}

@media screen and (min-width: 641px) and (max-width: 999px) {
    .scroller {
        margin: 0 calc(-1 * var(--desktop-container-gutter));
    }

    .scroller__inner {
        scroll-padding-left: var(--desktop-container-gutter);
    }

    .scroller--mobile-only {
        margin: 0;
        overflow: visible;
    }

    .scroller--mobile-only > .scroller__inner {
        margin-bottom: 0;
        padding-bottom: 0;
        overflow: visible;
    }
}

@keyframes skeletonShimmerAnimation {
    0% {
        opacity: .45;
    }

    100% {
        opacity: .9;
    }
}

.skeleton-container {
    will-change: opacity;
    animation: 1s linear infinite alternate skeletonShimmerAnimation;
}

.skeleton-text {
    background: var(--border-color);
    width: 100%;
    height: 10px;
}

.skeleton-image {
    background: var(--border-color);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.skeleton-paragraph .skeleton-text {
    margin-bottom: 8px;
}

.skeleton-paragraph .skeleton-text:last-child {
    width: 38%;
    margin-bottom: 0;
}

.plus-button {
    width: 10px;
    height: 10px;
    display: block;
    position: relative;
    top: calc(50% - 5px);
    right: 0;
}

.plus-button:before, .plus-button:after {
    content: "";
    background-color: currentColor;
    transition: transform .35s ease-in-out, opacity .35s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)rotate(-90deg);
}

.plus-button:before {
    opacity: 1;
    width: 10px;
    height: 2px;
}

.plus-button:after {
    width: 2px;
    height: 10px;
}

[aria-expanded="true"] .plus-button:before, [aria-expanded="true"] .plus-button:after {
    transform: translate(-50%, -50%)rotate(90deg);
}

[aria-expanded="true"] .plus-button:before {
    opacity: 0;
}

@media screen and (min-width: 641px) {
    .plus-button--large {
        width: 14px;
        height: 14px;
    }

    .plus-button--large:before {
        width: 14px;
    }

    .plus-button--large:after {
        height: 14px;
    }
}

.shopify-challenge__container {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.shopify-challenge__button.shopify-challenge__button {
    border-color: var(--primary-button-background);
    text-align: center;
    line-height: normal;
    font-weight: var(--text-font-bolder-weight);
    font-size: var(--base-text-font-size);
    cursor: pointer;
    background: var(--primary-button-background);
    color: var(--primary-button-text-color);
    border-radius: 2px;
    min-height: 48px;
    padding: 9px 30px;
    transition: background .25s ease-in-out, color .25s ease-in-out, border .25s ease-in-out;
    display: inline-block;
    position: relative;
}

.shopify-challenge__button.shopify-challenge__button:hover {
    background: rgba(var(--primary-button-background-rgb), .8);
}

@media screen and (min-width: 641px) {
    .shopify-challenge__container {
        margin-top: 100px;
        margin-bottom: 100px;
    }
}

.shopify-policy__container {
    max-width: 800px;
}

.shopify-policy__title {
    margin: 30px 0;
}

.shopify-policy__title h1 {
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    font-style: var(--heading-font-style);
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 24px));
    color: var(--heading-color);
    margin-bottom: 0;
    line-height: 1.45;
}

@media screen and (min-width: 641px) {
    .shopify-policy__title {
        margin: 30px 0;
    }

    .shopify-policy__title h1 {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 28px));
        line-height: 1.45;
    }
}

.announcement-bar {
    font-weight: var(--text-font-bolder-weight);
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 12px));
    border-bottom: 1px solid var(--header-border-color);
    display: block;
    position: relative;
}

.announcement-bar__content {
    margin-bottom: 0;
    padding: 4px 0;
    display: block;
}

.announcement-bar__content--center {
    text-align: center;
}

@media screen and (max-width: 640px) {
    .announcement-bar__inner {
        text-align: center;
    }
}

@media screen and (min-width: 641px) {
    .announcement-bar {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
        text-align: left;
    }

    .announcement-bar__inner {
        align-items: center;
        display: flex;
    }

    .announcement-bar__content {
        margin-right: auto;
        padding: 7px 0;
    }

    .announcement-bar__content--center {
        padding-left: var(--announcement-bar-button-width, 0px);
        margin-left: auto;
    }

    .announcement-bar__button {
        background: var(--header-accent-color);
        color: var(--secondary-background);
        box-shadow: 0 1px var(--header-accent-color);
        padding: 7px 18px 7px 15px;
    }

    .announcement-bar__button svg {
        vertical-align: text-bottom;
        width: 20px;
        height: 17px;
        margin-right: 12px;
        display: inline-block;
    }

    .announcement-bar__close-container {
        position: relative;
    }

    .announcement-bar__close {
        color: var(--heading-color);
        transition: color .2s ease-in-out;
        position: absolute;
        top: 25px;
        right: 0;
    }

    .announcement-bar__close:hover {
        color: var(--accent-color);
    }

    .announcement-bar__close svg {
        width: 19px;
        height: 19px;
    }

    .announcement-bar__newsletter {
        visibility: hidden;
        text-align: center;
        height: 0;
        transition: height .5s cubic-bezier(.77, 0, .175, 1), visibility .5s cubic-bezier(.77, 0, .175, 1);
        overflow: hidden;
    }

    .announcement-bar__newsletter[aria-hidden="false"] {
        visibility: visible;
    }

    .announcement-bar__newsletter-inner {
        margin: 50px 0;
    }
}

.offer-item {
    background: var(--secondary-background);
    border: 1px solid var(--border-color);
    border-radius: 3px;
    padding: 20px;
}

.offer-item__image-wrapper {
    min-width: 70px;
    max-width: 70px;
    margin-bottom: 14px;
}

.offer-item__title {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 17px));
    margin-bottom: 6px;
}

.offer-item__content {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 14px));
}

@media screen and (min-width: 641px) {
    .offer-item {
        padding: 28px 30px;
    }

    .offer-item__image-wrapper {
        min-width: 110px;
        max-width: 110px;
    }
}

@media screen and (min-width: 1280px) {
    .offer-item--inline {
        align-items: center;
        display: flex;
    }

    .offer-item--inline .offer-item__image-wrapper {
        margin: 0 30px 0 0;
    }
}

.image-with-text {
    flex-direction: column;
    display: flex;
}

.image-with-text__image-container {
    margin-bottom: 25px;
}

@media screen and (max-width: 999px) {
    .image-with-text__image-container {
        order: -1;
        width: 100% !important;
    }
}

@media screen and (min-width: 1000px) {
    .image-with-text {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .image-with-text__image-container {
        min-width: 30%;
        max-width: 50%;
    }

    .image-with-text__image-container, .image-with-text__text-container {
        width: 50%;
        margin: 0;
    }

    .image-with-text__text-container:last-child {
        padding-left: 60px;
    }

    .image-with-text__text-container:first-child {
        text-align: right;
        padding-right: 60px;
    }

    .image-with-text__text-container:first-child .image-with-text__text-aligner {
        text-align: left;
        display: inline-block;
    }
}

@media screen and (min-width: 1000px) {
    .image-with-text__image-container {
        min-width: 0;
        max-width: none;
    }
}

@media screen and (min-width: 1280px) {
    .image-with-text__text-container:last-child {
        padding-left: 75px;
    }

    .image-with-text__text-container:first-child {
        padding-right: 75px;
    }
}

.newsletter {
    margin-top: 30px;
}

.newsletter__form {
    padding-bottom: 10px;
}

.newsletter__text {
    margin-top: 16px;
}

.newsletter--compact .newsletter__form {
    padding-bottom: 0;
}

.newsletter--compact .newsletter__text {
    margin-top: 16px;
}

.quick-links {
    text-align: center;
    background: var(--secondary-background);
    border: 1px solid var(--border-color);
    font-size: calc(var(--default-text-font-size)  + 1px);
    border-radius: 3px;
    flex-wrap: wrap;
    list-style: none;
    display: flex;
}

.quick-links__link {
    box-shadow: 1px 0 var(--border-color), 0 1px var(--border-color);
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    text-transform: none;
    flex: none;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 20px 10px;
    transition: color .2s ease-in-out;
    display: flex;
}

.quick-links__link:hover {
    color: var(--accent-color);
}

.quick-links__link--grow {
    flex-grow: 1;
}

.quick-links__image-container {
    flex: auto;
    margin: 0 auto;
    font-size: 0;
    display: block;
}

.quick-links__image-ie-fix {
    margin: 0 auto;
    transition: transform .3s ease-in-out;
}

@media screen and (any-hover: hover) {
    .features--animate-zoom .quick-links__link:hover .quick-links__image-ie-fix {
        transform: scale(1.05);
    }
}

@media screen and (min-width: 641px) {
    .quick-links__link {
        width: 33.3333%;
        padding: 24px;
    }
}

@media screen and (min-width: 1000px) {
    .quick-links__link {
        width: 16.6667%;
    }
}

.breadcrumb__list {
    align-items: center;
    list-style: none;
    display: flex;
}

.breadcrumb__list svg {
    vertical-align: inherit;
    width: 6px;
    height: 8px;
    margin: 0 7px;
}

.loading-bar {
    opacity: 0;
    background: var(--accent-color);
    transform-origin: 0;
    z-index: 50;
    pointer-events: none;
    width: 100%;
    height: 3px;
    transition: transform .25s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    transform: scaleX(0);
}

.loading-bar.is-visible {
    opacity: 1;
}

.empty-state {
    text-align: center;
    margin: 100px 0;
}

.empty-state--extra-tight {
    margin: 30px 0;
}

.empty-state--tight {
    margin: 40px 0;
}

.empty-state__heading {
    margin-bottom: 15px;
}

.empty-state__text {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 15px));
    margin-bottom: 32px;
}

.empty-state__quick-form {
    max-width: 410px;
    margin: 40px auto 25px;
}

.empty-state__button-container {
    margin-top: 16px;
}

.empty-state__button {
    min-width: 230px;
}

.empty-state__icon {
    margin-bottom: 6px;
    display: inline-block;
    position: relative;
}

.empty-state__icon .icon--package {
    width: 46px;
    height: 46px;
}

.empty-state__icon .icon--address {
    width: 46px;
    height: 45px;
}

.empty-state__icon-description {
    margin-bottom: 30px;
}

.empty-state__count {
    line-height: 20px;
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 12px));
    font-weight: var(--text-font-bolder-weight);
    text-align: center;
    background: var(--accent-color);
    color: var(--header-text-color);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 2px;
    right: -9px;
}

@media screen and (min-width: 641px) {
    .empty-state {
        margin: 170px 0;
    }

    .empty-state--extra-tight {
        margin: 40px 0;
    }

    .empty-state--tight {
        margin: 105px 0;
    }
}

.payment-list {
    flex-wrap: wrap;
    margin: -4px;
    display: flex;
}

.payment-list__item {
    width: 38px;
    height: 24px;
    margin: 4px;
}

.payment-list__notice {
    margin-top: 18px;
}

.payment-list--centered {
    justify-content: center;
}

.layout {
    margin-bottom: 70px;
}

.layout .layout {
    margin-top: 18px;
    margin-bottom: 0;
}

.layout__section {
    margin-bottom: 18px;
}

.layout .card:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 999px) {
    .layout--reverse {
        flex-direction: column-reverse;
        display: flex;
    }
}

@media screen and (min-width: 641px) {
    .layout {
        margin-bottom: 100px;
    }

    .layout .layout {
        margin-top: 30px;
    }
}

@media screen and (min-width: 1000px) {
    .layout {
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
    }

    .layout--pad {
        padding-top: 25px;
    }

    .layout__section {
        flex: 1 0 0;
        margin: 0 15px;
    }

    .layout__section:only-child {
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;
    }

    .layout__section--secondary {
        flex: none;
        width: 260px;
    }

    .layout__section--large-secondary {
        flex: none;
        width: 320px;
    }
}

@media screen and (min-width: 1280px) {
    .layout__section--secondary {
        width: 288px;
    }

    .layout__section--large-secondary {
        width: 365px;
    }
}

.modal {
    z-index: 10;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
}

.modal:before {
    content: "";
    pointer-events: none;
    background: #00000059;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.modal[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
}

.modal[aria-hidden="false"] .modal__dialog {
    transition: transform .4s cubic-bezier(.18, 1.25, .4, 1), visibility .4s linear;
    transform: scale(1);
}

.modal__dialog {
    background: var(--secondary-background);
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    transition: transform .25s ease-in-out, visibility .25s ease-in-out;
    position: absolute;
    overflow: auto;
    transform: scale(.9);
    box-shadow: 0 1px 2px #0000004d;
}

.modal__loader {
    text-align: center;
    padding: 60px 0;
    display: none;
}

.modal__loader .icon--search-loader {
    color: var(--accent-color);
    width: 35px;
    height: 35px;
}

.modal__header {
    border-bottom: 1px solid var(--border-color);
    background: var(--secondary-background);
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: sticky;
    top: 0;
}

.modal__header, .modal__content {
    padding: 20px;
}

.modal__header + .modal__content {
    padding-top: 30px;
}

.modal__title {
    text-align: center;
    margin-bottom: 0;
}

.modal__description {
    text-align: center;
    margin-bottom: 30px;
    display: block;
}

.modal__close {
    color: var(--heading-color);
    z-index: 1;
}

.modal__close svg {
    width: 20px;
    height: 20px;
    display: block;
}

.modal .card {
    background: none;
}

.modal.is-loading .modal__loader {
    display: block;
}

.modal.is-loading .modal__inner {
    display: none;
}

@media screen and (max-width: 640px) {
    .modal__content--ios-push {
        padding-bottom: 45px;
    }
}

@media screen and (min-width: 641px) {
    .modal {
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .modal__dialog {
        border-radius: 3px;
        width: 580px;
        height: auto;
        max-height: calc(100% - 80px);
    }

    .modal__dialog--stretch {
        width: calc(100% - 80px);
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }

    .modal__header, .modal__content {
        padding: 30px;
    }

    .modal__header:not(.modal__header--bordered) {
        text-align: center;
        border-bottom: none;
        padding-bottom: 0;
        display: block;
        position: relative;
    }

    .modal__header--bordered {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .modal__close {
        position: absolute;
        top: 28px;
        right: 28px;
    }

    .modal__description {
        margin-top: -18px;
    }
}

.icon-state {
    vertical-align: middle;
    position: relative;
}

.icon-state .icon-state__primary, .icon-state .icon-state__secondary {
    transition: opacity .35s ease-in-out, transform .35s ease-in-out;
    display: block;
}

.icon-state .icon-state__secondary {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%)scale(.3);
}

.icon-state[aria-expanded="true"] .icon-state__primary {
    opacity: 0;
    transform: scale(.3);
}

.icon-state[aria-expanded="true"] .icon-state__secondary {
    opacity: 1;
    transform: translateY(-50%)scale(1);
}

@media (-moz-touch-enabled: 0), (hover: hover) {
    [data-tooltip] {
        position: relative;
    }

    [data-tooltip]:before {
        content: attr(data-tooltip);
        white-space: nowrap;
        background: var(--accent-color);
        color: var(--secondary-background);
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        z-index: 1;
        border-radius: 3px;
        padding: 2px 11px;
        transition: visibility .2s ease-in-out, opacity .2s ease-in-out;
        position: absolute;
        bottom: calc(100% + 3px);
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0 1px #0001;
    }

    [data-tooltip]:after {
        content: "";
        border-style: solid;
        border-width: 6px;
        border-color: transparent transparent var(--accent-color) var(--accent-color);
        visibility: hidden;
        z-index: 1;
        opacity: 0;
        width: 0;
        height: 0;
        transition: visibility .2s ease-in-out, opacity .2s ease-in-out;
        position: absolute;
        bottom: calc(100% - 2px);
        left: calc(50% - 7px);
        transform: rotate(-45deg);
        box-shadow: -1px 1px 1px #0000001a;
    }

    [data-tooltip]:hover:before, [data-tooltip]:hover:after {
        opacity: 1;
        visibility: visible;
    }

    [data-tooltip-position="bottom-left"]:before {
        top: calc(100% + 4px);
        bottom: auto;
        left: auto;
        right: -6px;
        transform: none;
    }

    [data-tooltip-position="bottom-left"]:after {
        top: calc(100% - 1px);
        left: calc(50% - 6px);
        transform: rotate(135deg);
    }
}

.quantity-selector {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 15px));
    border: 1px solid var(--border-color);
    box-shadow: 0 1px 1px rgba(var(--border-color-rgb), .2);
    vertical-align: middle;
    border-radius: 3px;
    align-items: center;
    height: 38px;
    display: inline-flex;
}

.quantity-selector svg:hover {
    opacity: 1;
}

.quantity-selector .icon--minus {
    width: 10px;
    height: 2px;
}

.quantity-selector .icon--plus {
    width: 10px;
    height: 10px;
}

.quantity-selector__button {
    color: rgba(var(--text-color-rgb), .6);
    touch-action: manipulation;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    transition: color .2s ease-in-out;
    display: flex;
}

.quantity-selector__button:not([disabled]):hover {
    color: var(--heading-color);
}

.quantity-selector__value {
    -webkit-appearance: none;
    appearance: none;
    text-align: center;
    background: none;
    border: none;
    min-width: 32px;
    padding: 0 5px;
}

.quantity-selector__value:focus {
    outline: none;
}

.quantity-selector--product {
    height: 44px;
}

.quantity-selector--product .quantity-selector__button {
    padding: 0 16px;
}

.quantity-selector--product .quantity-selector__value {
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    box-shadow: 0 1px rgba(var(--border-color-rgb), .4) inset;
    color: var(--heading-color);
    min-width: 64px;
    height: 100%;
}

.shipping-estimator__results {
    margin: 24px 0 2px;
    font-size: 1rem;
}

.shipping-estimator__results p {
    margin-bottom: 6px;
}

.shipping-estimator__results ul {
    color: var(--heading-color);
    font-weight: var(--text-font-bolder-weight);
}

.cookie-bar {
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 11px));
    width: 100%;
    transition: transform .35s ease-in-out, opacity .35s ease-in-out, visibility .35s ease-in-out;
    position: fixed;
    bottom: 20px;
    left: 0;
    transform: translateY(100%);
}

.cookie-bar[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.cookie-bar__inner {
    z-index: 4;
    background: var(--secondary-background);
    color: var(--text-color);
    border-radius: 3px;
    max-width: 960px;
    margin: 0 auto;
    padding: 15px;
    box-shadow: 0 1px 4px 1px #0000001a;
}

.cookie-bar__text {
    line-height: 1.45;
}

.cookie-bar__button {
    line-height: 30px;
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 12px));
    margin-top: 12px;
    padding: 0 16px;
}

.cookie-bar__choice-container {
    margin-top: 5px;
}

@supports (padding: max(0px)) {
    .cookie-bar {
        bottom: max(25px, env(safe-area-inset-bottom, 0px)  + 25px);
    }
}

@media screen and (min-width: 641px) {
    .cookie-bar {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
        bottom: 25px;
    }

    .cookie-bar__inner {
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px 20px 25px;
        display: flex;
    }

    .cookie-bar__text {
        line-height: 1.25;
    }

    .cookie-bar__button {
        line-height: 36px;
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
        margin-top: 0;
        padding: 0 18px;
    }

    .cookie-bar__choice-container {
        min-width: -moz-fit-content;
        min-width: fit-content;
        margin-top: 5px;
        margin-left: 25px;
    }
}

.contact {
    margin-bottom: 40px;
}

.contact__store-info {
    margin-top: 50px;
}

.contact__store-heading, .contact__store-image {
    margin-bottom: 30px;
}

@media screen and (min-width: 641px) {
    .contact {
        margin-bottom: 90px;
    }
}

@media screen and (min-width: 1000px) {
    .contact__store-info {
        margin-top: 0;
        margin-left: 25px;
    }
}

.password {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px 0;
    display: flex;
}

@supports (--css: variables) {
    .password {
        height: calc(100vh - var(--header-height, 0px));
    }
}

.password__content {
    text-align: center;
    flex: 1 0 auto;
    align-items: center;
    display: flex;
}

.password__footer {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    text-align: center;
}

.password__footer-social {
    justify-content: center;
    margin-top: 15px;
    display: flex;
}

.password__social-text {
    margin-right: 20px;
}

.password__powered-by a {
    vertical-align: bottom;
    margin-left: 4px;
    display: inline-block;
}

.password__powered-by svg {
    width: 98px;
    height: 28px;
}

.password__admin-link {
    margin-left: 18px;
    display: block;
}

@media screen and (min-width: 1000px) {
    .template-password .header {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .password {
        padding: 40px 0;
    }

    .password__footer {
        text-align: left;
    }

    .password__footer--with-social {
        justify-content: space-between;
        width: 100%;
        display: flex;
    }

    .password__admin-link {
        display: inline-block;
    }

    .password__footer-social {
        margin-top: 0;
    }
}

.faq {
    margin: 40px 0 70px;
}

.faq__title {
    text-align: center;
    margin-bottom: 20px;
}

.faq__item {
    background: var(--secondary-background);
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding: 20px;
}

.faq__item + .faq__item {
    border-top: none;
}

.faq__question {
    text-align: left;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
}

.faq__question .plus-button {
    margin-left: 40px;
}

.faq__answer-wrapper {
    height: 0;
    transition: height .2s ease-in-out;
    overflow: hidden;
}

.faq__answer {
    margin: 10px 30px 0 0;
}

.faq__group {
    margin-bottom: 30px;
}

.faq__group-title {
    text-align: center;
}

.faq__contact-info {
    margin-top: 50px;
}

@media screen and (min-width: 641px) {
    .faq {
        margin: 70px 0 115px;
    }

    .faq__title {
        margin-bottom: 25px;
        margin-left: 0;
    }

    .faq__item {
        border: 1px solid var(--border-color);
        border-radius: 3px 3px 0 0;
        padding: 20px 30px;
    }

    .faq__item + .faq__item {
        border-radius: 0;
    }

    .faq__item:last-child {
        border-radius: 0 0 3px 3px;
    }
}

@media screen and (min-width: 1000px) {
    .faq__group {
        margin-bottom: 0;
    }

    .faq__group + .faq__group {
        margin-top: 50px;
    }
}

.modal--newsletter {
    height: auto;
    top: auto;
    bottom: 0;
}

.modal--newsletter .modal__dialog {
    height: auto;
    padding: 48px 20px 20px;
    position: relative;
}

.popup-newsletter__title {
    text-align: center;
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 20px));
    margin-bottom: 10px;
}

.popup-newsletter__close {
    color: var(--heading-color);
    position: absolute;
    top: 16px;
    right: 20px;
}

.popup-newsletter__close svg {
    width: 15px;
    height: 15px;
}

.popup-newsletter__content {
    text-align: center;
}

.popup-newsletter__form {
    margin-top: 20px;
}

@supports (padding: max(0px)) {
    .modal--newsletter .modal__dialog {
        padding-bottom: max(20px, env(safe-area-inset-bottom, 0px)  + 20px);
    }
}

@media screen and (min-width: 641px) {
    .modal--newsletter {
        height: 100%;
    }

    .modal--newsletter .modal__dialog {
        max-width: 540px;
        padding: 64px 80px 80px;
    }

    .popup-newsletter__close {
        position: absolute;
        top: 24px;
        right: 30px;
    }

    .popup-newsletter__close svg {
        width: 20px;
        height: 20px;
    }

    .popup-newsletter__title {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 28px));
    }

    .popup-newsletter__form {
        margin-top: 30px;
    }
}

.modal--exit-popup {
    height: auto;
    top: auto;
    bottom: 0;
}

.modal--exit-popup .modal__dialog {
    height: auto;
    padding: 48px 20px 20px;
    position: relative;
}

.exit-popup__title {
    text-align: center;
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 28px));
    margin-bottom: 8px;
    line-height: 1.2;
}

.exit-popup__subheading {
    text-align: center;
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 18px));
}

.exit-popup__close {
    color: var(--heading-color);
    position: absolute;
    top: 16px;
    right: 20px;
}

.exit-popup__close svg {
    width: 15px;
    height: 15px;
}

.exit-popup__form {
    margin-top: 20px;
}

.exit-popup__pay-more {
    text-align: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

@media screen and (min-width: 641px) {
    .modal--exit-popup {
        height: 100%;
    }

    .modal--exit-popup .modal__dialog {
        max-width: 540px;
        padding: 95px 80px 30px;
    }

    .exit-popup__close {
        position: absolute;
        top: 24px;
        right: 30px;
    }

    .exit-popup__close svg {
        width: 20px;
        height: 20px;
    }

    .exit-popup__title {
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 48px));
    }

    .exit-popup__form {
        margin-top: 30px;
    }

    .exit-popup__pay-more {
        margin-top: 75px;
    }
}

.drawer {
    z-index: 5;
    visibility: hidden;
    width: 100vw;
    height: 100vh;
    transition: visibility .35s linear;
    position: fixed;
    top: 0;
    right: 0;
}

.drawer:before {
    content: "";
    opacity: 0;
    background: #000;
    width: 100%;
    height: 100%;
    transition: opacity .35s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
}

.drawer__inner {
    background: var(--secondary-background);
    width: 100%;
    max-width: 345px;
    height: 100%;
    margin-left: auto;
    transition: transform .35s cubic-bezier(.645, .045, .355, 1);
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
}

.drawer[aria-hidden="false"] {
    visibility: visible;
}

.drawer[aria-hidden="false"]:before {
    opacity: .4;
}

.drawer[aria-hidden="false"] .drawer__inner {
    transform: translateX(0);
}

.link-search-results:not(:only-child) {
    margin-top: 40px;
}

.link-search-results__list {
    list-style: none;
}

.link-search-results__link {
    padding: 8px 20px;
    transition: color .15s ease-in-out, background .15s ease-in-out;
    display: block;
}

.link-search-results__link:hover, .link-search-results__link:focus {
    background: var(--accent-background);
    color: var(--accent-color);
    outline: none;
}

@media screen and (min-width: 641px) {
    .link-search-results:not(:only-child) {
        margin-top: 60px;
    }
}

.expandable-content[aria-expanded] {
    max-height: 320px;
    transition: all .2s ease-in-out;
    position: relative;
    overflow: hidden;
}

.expandable-content__toggle {
    visibility: hidden;
    opacity: 0;
    color: var(--accent-color);
    text-align: left;
    font-weight: var(--text-font-bolder-weight);
    background: var(--secondary-background);
    z-index: 1;
    align-items: center;
    width: 100%;
    padding: 16px 20px;
    transition: opacity .1s ease-in-out, visibility .1s ease-in-out;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
}

.expandable-content__toggle:before {
    content: "";
    pointer-events: none;
    background: linear-gradient(rgba(var(--secondary-background-rgb), 0), rgba(var(--secondary-background-rgb), .4) 15%, var(--secondary-background));
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 100%;
    left: 0;
}

.expandable-content__toggle-icon {
    width: 10px;
    height: 10px;
    margin-right: 15px;
    transition: transform .2s ease-in-out;
    position: relative;
}

.expandable-content__toggle-icon:before, .expandable-content__toggle-icon:after {
    content: "";
    background: currentColor;
    transition: transform .2s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.expandable-content__toggle-icon:before {
    width: 2px;
    height: 10px;
}

.expandable-content__toggle-icon:after {
    width: 10px;
    height: 2px;
}

.expandable-content--expandable[aria-expanded="true"] .expandable-content__toggle-icon {
    transform: rotateZ(90deg);
}

.expandable-content--expandable[aria-expanded="true"] .expandable-content__toggle-icon:after {
    display: none;
}

.expandable-content--expandable .expandable-content__toggle {
    visibility: visible;
    opacity: 1;
}

.expandable-content--expandable[aria-expanded="true"] .expandable-content__toggle:before {
    height: 0;
}

@media screen and (any-hover: hover) {
    .expandable-content[aria-expanded="false"] .expandable-content__toggle:hover .expandable-content__toggle-icon {
        transform: rotateZ(45deg);
    }
}

@media screen and (min-width: 641px) {
    .expandable-content__toggle {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.menu-item__title {
    margin-bottom: .75em;
}

.menu-item__linklist {
    list-style: none;
}

.menu-item__link-item {
    padding: 5px 0;
}

.price-range {
    padding: 20px 0;
    display: block;
}

.price-range__input-group {
    align-items: center;
    display: flex;
}

.price-range__input {
    flex: 1 0 0;
    min-width: 0;
}

.price-range__delimiter {
    margin: 0 12px;
}

.price-range__range-group {
    margin-bottom: 18px;
    position: relative;
}

.rating {
    vertical-align: bottom;
    line-height: inherit;
    align-items: center;
    display: flex;
}

.rating__stars {
    grid-auto-flow: column;
    column-gap: 2px;
    display: grid;
}

.rating__star {
    color: var(--product-review-star-color);
    width: 14px;
    height: 14px;
}

.rating__star--empty {
    color: rgba(var(--text-color-rgb), .4);
}

.rating__caption {
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 13px));
    margin-left: 7px;
    position: relative;
    top: 1px;
}

product-rerender {
    display: contents;
}

.value-picker-button svg {
    width: 9px;
    height: 7px;
    margin-left: 10px;
    transition: transform .2s ease-in-out;
}

.value-picker-button svg path {
    stroke-width: 2.5px;
}

.value-picker-button--pill {
    text-align: left;
    border-bottom: 1px solid var(--border-color);
    background: var(--secondary-background);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 14px 20px;
    display: flex;
    position: relative;
}

.value-picker-button--pill svg {
    width: 12px;
    height: 8px;
    transition: transform .2s ease-in-out;
}

.value-picker-button--pill path {
    stroke-width: 2px;
}

.value-picker-button[aria-expanded="true"] svg {
    transform: rotateZ(180deg);
}

.value-picker {
    z-index: 5;
    font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 16px));
    visibility: hidden;
    width: 100vw;
    height: 100vh;
    transition: visibility .35s ease-in-out;
    position: fixed;
    bottom: 0;
    left: 0;
}

.value-picker:before {
    content: "";
    opacity: 0;
    background: #000;
    width: 100vw;
    height: 100vh;
    transition: opacity .35s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
}

.value-picker[aria-hidden="false"] {
    visibility: visible;
}

.value-picker[aria-hidden="false"]:before {
    opacity: .4;
}

.value-picker[aria-hidden="false"] .value-picker__inner {
    transform: translateY(0);
}

.value-picker .icon--nav-triangle-borderless {
    display: none;
}

.value-picker__inner {
    background: var(--secondary-background);
    width: 100%;
    transition: visibility .2s ease-in-out, transform .2s ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
}

.value-picker__header {
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    align-items: center;
    padding: 17px 20px 16px;
    display: flex;
}

.value-picker__header .icon--close {
    color: var(--heading-color);
    width: 17px;
    height: 17px;
}

.value-picker__title {
    margin: 0;
}

.value-picker__close svg {
    display: block;
}

.value-picker__choice-list {
    overscroll-behavior: none;
    max-height: 430px;
    padding: 15px 0;
    list-style: none;
    overflow: auto;
}

.value-picker__choice-item {
    text-align: left;
    width: 100%;
    padding: 8px 50px 7px 20px;
    display: block;
    position: relative;
}

.value-picker__choice-item svg {
    width: 13px;
    height: 11px;
    transition: transform .2s ease-in-out;
    position: absolute;
    top: 17px;
    right: 20px;
    transform: scale(0);
}

.value-picker__choice-item.is-selected {
    color: var(--accent-color);
    font-weight: var(--text-font-bolder-weight);
}

.value-picker__choice-item.is-selected svg {
    transform: scale(1);
}

@supports (padding: max(0px)) {
    .value-picker__choice-list {
        max-height: calc(430px + env(safe-area-inset-bottom, 0px)  + 15px);
        padding-bottom: max(15px, env(safe-area-inset-bottom, 0px)  + 15px);
    }
}

@media screen and (min-width: 641px) {
    .value-picker-wrapper {
        position: relative;
    }

    .value-picker {
        visibility: hidden;
        opacity: 0;
        background: var(--secondary-background);
        color: var(--text-color);
        z-index: 4;
        cursor: auto;
        will-change: transform;
        border-radius: 3px;
        width: auto;
        min-width: 160px;
        height: auto;
        transition: opacity .25s ease-in-out, transform .25s ease-in-out, visibility .25s ease-in-out;
        position: absolute;
        top: calc(100% + 10px);
        bottom: auto;
        left: auto;
        right: -25px;
        transform: scale(.9);
        box-shadow: 0 1px 5px 2px #0000001a;
    }

    .value-picker:before {
        display: none;
    }

    .value-picker[data-picker-position="top"] {
        top: auto;
        bottom: calc(100% + 10px);
    }

    .value-picker[aria-hidden="false"] {
        visibility: visible;
        opacity: 1;
        transition: opacity .4s cubic-bezier(0, 1, .4, 1), transform .4s cubic-bezier(.18, 1.25, .4, 1), visibility .4s linear;
        transform: scale(1.001);
    }

    .value-picker .icon--nav-triangle-borderless {
        z-index: 2;
        filter: drop-shadow(0 -2px 2px #0000001f);
        width: 18px;
        height: 8px;
        display: block;
        position: absolute;
        bottom: 100%;
        right: 20px;
    }

    .value-picker[data-picker-position="top"] .icon--nav-triangle-borderless {
        top: 100%;
        bottom: auto;
        transform: rotateZ(180deg);
    }

    .value-picker__inner {
        border-radius: 3px;
        width: auto;
        transition: none;
        position: relative;
        transform: none !important;
    }

    .value-picker__inner--restrict {
        max-height: 300px;
        overflow: auto;
    }

    .value-picker__header {
        display: none;
    }

    .value-picker__choice-list {
        white-space: nowrap;
        font-size: calc(var(--base-text-font-size)  - (var(--default-text-font-size)  - 15px));
        max-height: none;
        padding: 15px 0;
    }

    .value-picker__choice-item {
        cursor: pointer;
        padding: 2px 25px;
        transition: color .15s ease-in-out, background .15s ease-in-out;
        display: block;
    }

    .value-picker__choice-item.is-selected, .value-picker__choice-item:hover {
        background: var(--accent-background);
        color: var(--accent-color);
        font-weight: normal;
    }

    .value-picker__choice-item.is-selected svg, .value-picker__choice-item:hover svg {
        display: none;
    }

    .value-picker--small {
        min-width: 105px;
        right: -2px;
    }

    .value-picker--small .value-picker__choice-list {
        padding: 10px 0;
        font-size: .85rem;
    }

    .value-picker--small .value-picker__choice-item {
        padding: 2px 18px;
    }

    .value-picker--small .icon--nav-triangle-borderless {
        right: 11px;
    }

    .value-picker--auto {
        min-width: 0;
    }
}